import React from "react";
import Containers from "../../components/Containers";
import ChartManager from "./container/ChartManager";
import ConstructionPage from "../../components/BuildingPages";

const Dashboard = () => {
  document.title = "Dashboard";
  const tabs = [
    {
      name: "Qr tracking",
      content: <ChartManager />,
      permission: "STAFF",
    },
    {
      name: "Tracking webSite",
      content: <ConstructionPage />,
      permission: "STAFF",
    },
  ];
  return <Containers tabs={tabs} title="Dashboard" />;
};

export default Dashboard;
