import { Route, Routes } from "react-router";
import RouteGuard from "./guard/RouteGuard";
import { isAuthenticate } from "./services/authContext";
import LoginPage from "./Pages/Login/Login";
import Dashboard from "./Pages/dashBoard/Dashboard";
import ConnectedLayout from "./components/Layout/ConnectedLayout";
import ErrorPage from "./Pages/Error/Error";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import FRONTEND_ERRORS from "./utils/ErrorsCode";

const AllowedRouting = ({ children, pathname }) => {
  const navigate = useNavigate();

  const allowedRoutes = useSelector((state) => state.route.FetchingRoute).map(
    (el) => el.link,
  );
  const errorInfo = FRONTEND_ERRORS.getErrorInfo("ACCESS_DENY");
  if (allowedRoutes.includes(pathname)) {
    return children;
  } else {
    navigate("/error", {
      state: {
        code: "ACCESS_DENY",
        status: errorInfo.httpCode,
        message: errorInfo.message,
      },
    });
    return null;
  }
};
function App() {
  return (
    <Routes>
      {/*Accès connection*/}
      <Route path="/" element={<LoginPage />} />
      {/*Accès compte*/}
      <Route
        path="/dashboard"
        element={
          <RouteGuard checkFn={isAuthenticate}>
            <ConnectedLayout />
          </RouteGuard>
        }
      >
        <Route path="/dashboard" element={<Dashboard />} />
      </Route>

      {/*<Route*/}
      {/*    path="/dashboard"*/}
      {/*    element={*/}
      {/*        {<AllowedRouting pathname="/dashboard">}*/}
      {/*<Dashboard />*/}
      {/*    /!*</AllowedRouting>*!/*/}
      {/*    }*/}
      {/*    />*/}

      {/*Accès Error*/}
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

export default App;
