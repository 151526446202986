import React from "react";
import { FaTools } from "react-icons/fa";
import image from "../assets/BuildingSite.webp";
const ConstructionPage = () => {
  return (
    <div className="h-[70vh] bg-gray-100 flex items-center justify-center p-4">
      <div className="text-center bg-white shadow-lg rounded-lg p-8 max-w-md w-full">
        <div className="flex justify-center items-center justify-center mb-6">
          <FaTools className="text-6xl text-yellow-500 mr-4" />
        </div>
        <h1 className="text-3xl font-bold text-gray-800 mb-4">
          Site en Construction
        </h1>
        <p className="text-gray-600 mb-6">
          Nous travaillons actuellement à l'amélioration de notre site web.
          Revenez bientôt pour découvrir quelque chose d'incroyable !
        </p>
        <img
          src={image}
          alt="En construction"
          className="mx-auto rounded-lg shadow-md mb-6"
        />
      </div>
    </div>
  );
};

export default ConstructionPage;
