// ErrorPage.js

import React from "react";
import { useLocation } from "react-router-dom";
import FRONTEND_ERRORS from "../../utils/ErrorsCode";
import Button from "../../components/Button";
import { FaArrowRightLong } from "react-icons/fa6";
function ErrorPage() {
  const location = useLocation();

  const { code, status, message } = location.state || {};
  document.title = `Erreur : ${status}  ${code} `;

  const errorInfo = FRONTEND_ERRORS.getErrorInfo(code);

  return (
    <div className="flex items-center justify-center min-h-screen bg-custom-gradient">
      <div className="bg-white p-8 rounded-lg shadow-lg text-center max-w-md flex flex-col gap-4">
        <h1 className="text-6xl font-bold text-red-600 mb-4">
          {status || errorInfo.httpCode}
        </h1>
        <h2 className="text-2xl font-semibold text-gray-800 mb-2">
          Oups ! Une erreur est survenue.
        </h2>
        {code && (
          <p className="text-lg text-gray-600 mb-4">Code d'erreur : {code}</p>
        )}
        {message || (
          <p className="text-base text-gray-600 mb-4">{errorInfo.message}</p>
        )}
        <div className="mx-auto pt-8">
          <Button
            type="link"
            href={"/"}
            withIcons={true}
            iconsPosition="right"
            icon={<FaArrowRightLong />}
            size="large"
            variant="flat"
            className=" w-60"
          >
            Retour à l'accueil
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;
